import { WenNetwork } from '@portal/wen-backend-api';
import { EnvironmentType } from './environment-type';

export const environment: EnvironmentType = {
  production: true,
  versionType: 'development',
  backendConfig: {
    backendUrl: 'https://websocket-bioland.development.we.network/',
    mediaServiceBaseUrl: 'https://media-bioland.development.we.network/',
    network: WenNetwork.BIOLAND
  },
  oauthConfig: {
    postLogoutRedirectUri: '/signin/start',
    clientId: 'wen-bioland-d78e60a6-e3bd-4805-a502-f3766fad7378',
    issuer: 'https://identity-bioland.development.we.network',
    tokenEndpoint: 'https://identity-bioland.development.we.network/oauth/token',
    logoutUrl: 'https://identity-bioland.development.we.network/forcelogout',
    revocationEndpoint: 'https://identity-bioland.development.we.network/oauth/token/revoke'
  },
  weLocalBaseUrl: 'https://multimedia.we.network/',
  yellowMapConfig: {
    yellowMapUrl: 'https://www.yellowmap.de',
    yellowMapApiKey: 'dXsavGwBsiBmtLMq8+C0Y5iufdUC5/ZoS35Pu8v9Dv0='
  },
  contactInfoConfig: {
    supportEmail: 'bioland-app@bioland.de',
    hotline: [
      {
        fieldName: 'Bioland direkt für Erzeuger (Deutschland)',
        phoneNumber: '+490000000'
      },
      {
        fieldName: 'Bioland-Hotline Südtirol',
        phoneNumber: '+490000001'
      },
      {
        fieldName: 'Bioland Partner direkt',
        phoneNumber: '+490000002'
      },
      {
        fieldName: 'Team Markennutzung',
        phoneNumber: '+490000003'
      },
      {
        fieldName: 'Team Direktvermarktung',
        phoneNumber: '+490000004'
      },
      {
        fieldName: 'Die Grüne Nummer (Fragen zur Biodiversitätsrichtlinie)',
        phoneNumber: '+490000005'
      }
    ]
  },
  appConfig: {
    enablePwa: true,
    enableChat: true,
    enableDevTools: true,
    enableChatDebugMode: false,
    enableEvent: true,
    enableAppStatePersistence: true,
    enableAppBanner: false,
    enableAgb: false,
    enableInviteOnlyNetwork: false,
    enableInviteToNetwork: false,
    enableDisconnectErrorPage: true,
    enableShareMenu: true,
    enableCRMDistributionCancelling: false
  },
  enableEvents: true,
  dataProtectionConfig: {
    impressumUrl: 'https://www.bioland.de/impressum-app',
    termsAndConditionsUrl: 'https://www.bioland.de/agb-app',
    privacyPolicyUrl: 'https://www.bioland.de/datenschutz-app',
    manifestEnabled: true,
    netiquetteUrl: 'https://www.bioland.de/netiquette-app',
    communityGuidelineUrl: null
  },
  nativeConfig: {
    useNativeDialog: true,
    nativeDeepLink: 'wen-bioland',
    storeInfos: {
      androidPlayStoreId: 'network.we.bioland',
      iosAppName: 'bioland-vernetzen-entdecken',
      iosAppStoreId: '1597739213'
    }
  },
  weatherConfig: {
    location: 'Mainz',
    countryCode2Letters: 'DE'
  },
  debugOptions: {
    enableAutoRefreshByAssetChanges: true,
  }
};
