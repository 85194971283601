import { HttpClientModule } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { WenComponentsModule } from '@portal/wen-components';
import { WenStorePersistenceModule } from '@portal/wen-store-persistence';
import {
  AvailableRoutes,
  HomePageConfig,
  HomePageEntries,
  HOME_PAGE_CONFIG,
  NetworkModule,
  ROOT_REDUCERS,
  WenApiModule,
  WenChatModule,
  WenEmbeddedApiModule,
  WenRoutingModule,
  WenTranslateLoader,
  WenWidgetModule,
  WEN_NETWORK_INITIALIZATION_PROVIDER
} from '@portal/wen-widget';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { BIOLAND_CLIENT_CUSTOMIZATION_PROVIDER } from './core/providers/bioland-customization-provider';
import { BIOLAND_RADIUS_CONFIGURATION } from './core/providers/bioland-radius-configuration';

const versionNum = packageJson.dependencies['@portal/wen-widget'].replace('^', '');
const versionString = `Version: ${versionNum}${environment.versionType ? `-${environment.versionType}` : ''}`;

const WEN_CORE_MODULES = [
  WenComponentsModule.forRoot(),
  WenRoutingModule.forRoot({
    startPage: AvailableRoutes.HOME
  }),
  WenWidgetModule,
  WenApiModule.forRoot({
    backendConfig: environment.backendConfig,
    oauthConfig: environment.oauthConfig,
    weLocalBaseUrl: environment.weLocalBaseUrl,
    yellowMapConfig: environment.yellowMapConfig,
    appConfig: environment.appConfig,
    dataProtectionConfig: environment.dataProtectionConfig,
    contactInfoConfig: environment.contactInfoConfig,
    nativeConfig: environment.nativeConfig,
    weatherConfig: environment.weatherConfig,
    versionInfo: {
      versionString
    },
    debugOptions: environment.debugOptions,
  }),
  WenChatModule.forRoot(),
  NetworkModule.forRoot({
    clientCustomizationProvider: BIOLAND_CLIENT_CUSTOMIZATION_PROVIDER,
    networkInitializationHandler: WEN_NETWORK_INITIALIZATION_PROVIDER
  }),
  WenEmbeddedApiModule.forRoot(),
  WenStorePersistenceModule.forRoot()
];

const WEN_PEER_MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: WenTranslateLoader
    },
    compiler: {
      provide: TranslateCompiler,
      useClass: TranslateMessageFormatCompiler
    }
  }),
  StoreRouterConnectingModule.forRoot(),
  StoreModule.forRoot(ROOT_REDUCERS, {}),
  EffectsModule.forRoot([]),
  HttpClientModule,
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ...WEN_CORE_MODULES,
    ...WEN_PEER_MODULES,
    environment.appConfig.enablePwa ? ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }) : []
  ],
  providers: [
    {
      provide: HOME_PAGE_CONFIG,
      useValue: {
        [HomePageEntries.FEEDBACK]: {
          disabled: true,
          goToLink: () => {
            window.open('mailto:bioland-app@bioland.de');
          }
        },
        [HomePageEntries.WELCOME_TEXT]: {
          disabled: true,
          goToLink: undefined
        }
      } as HomePageConfig
    },
    BIOLAND_RADIUS_CONFIGURATION
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
